import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { HorizontalBlurShader } from "three/examples/jsm/shaders/HorizontalBlurShader";
import { VerticalBlurShader } from "three/examples/jsm/shaders/VerticalBlurShader";
import { createNoise3D } from 'simplex-noise';

const icon = process.env.PUBLIC_URL + '/img/icon-x.png';
console.log(process.env.PUBLIC_URL)

const LogoParticles = () => {
    const canvasRef = useRef(null);
    const imgSize = useRef({ width: 0, height: 0 });
    const particlesGeometryRef = useRef(null);
    const smallParticlesGeometryRef = useRef(null);
    const smallParticlesRef = useRef({});
    const imgDataRef = useRef(null);

    // Halve the rotation speed to slow down the animation
    const rotationSpeed = 0.005; // Original was 0.01

    // Initialize mouse position tracking
    const mousePosition = useRef({
        x: 0,
        y: 0,
        previousX: 0,
        previousY: 0,
        isActive: false,
        lastMoveTime: 0,
    });

    // Initialize particle offsets and delays due to mouse interaction
    const particleMouseOffsetsRef = useRef(null);
    const particleDelaysRef = useRef([]);

    // Initialize noise function
    const noise3D = useRef(createNoise3D());

    // Adjustable speed factor for snowflakes (small particles)
    const snowflakeSpeedFactor = 0.25; // Reduced by half to slow down snowflakes (original was 0.5)

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.OrthographicCamera(-250, 250, 250, -250, 1, 1000);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
        renderer.setClearColor(0x000000, 0); // Make background fully transparent

        const canvasWidth = 700;
        const canvasHeight = 400;
        renderer.setSize(canvasWidth, canvasHeight);

        const composer = new EffectComposer(renderer);

        const loader = new THREE.TextureLoader();
        loader.load(icon, (texture) => {
            const img = texture.image;
            imgSize.current = { width: img.width, height: img.height };

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);

            const imgData = ctx.getImageData(0, 0, img.width, img.height);
            imgDataRef.current = imgData;

            const particlesGeometry = new THREE.BufferGeometry();
            const smallParticlesGeometry = new THREE.BufferGeometry();
            const positions = [];
            const colors = [];
            const sizes = [];
            const angles = [];
            const windOffsets = [];
            const alphas = []; // Array for vertex transparency

            const smallPositions = [];
            const smallVelocities = [];
            const smallAccelerations = [];
            const smallLifetimes = [];
            const smallColors = [];
            const smallOpacities = [];
            const waveParams = [];
            const delays = [];
            const smallSizes = [];
            const fadeDelays = [];
            const mergedStatus = [];

            const snowflakeSizes = [0.5, 0.7, 1.0, 1.3, 1.5, 1.7, 2.0, 2.3, 2.5, 3.0];
            const step = 5;

            const generateGrayColor = () => {
                const intensity = Math.random() * 0.1 + 0.6; // Range [0.6, 0.9]
                return [intensity, intensity, intensity];
            };

            const generateRandomSize = () => {
                return snowflakeSizes[Math.floor(Math.random() * snowflakeSizes.length)];
            };

            const generateSmallParticleColor = () => {
                const intensity = Math.random() * 0.1 + 0.6;
                return [intensity, intensity, intensity];
            };

            const generateRandomAngle = () => {
                return Math.random() * Math.PI * 2;
            };

            const generateAcceleration = () => {
                return {
                    x: (Math.random() * -0.0025 - 0.00125) * snowflakeSpeedFactor, // Negative x acceleration
                    y: (Math.random() * 0.0025 + 0.0025) * snowflakeSpeedFactor,
                };
            };

            const generateWaveParams = () => {
                return {
                    amplitude: Math.random() * 2 + 1,
                    frequency: Math.random() * 0.02 + 0.005,
                };
            };

            const isEdgePixel = (x, y) => {
                const index = (y * img.width + x) * 4;
                const alpha = imgData.data[index + 3];

                if (alpha < 200) return false;

                // Check neighbors within a radius of 2 pixels
                for (let offsetY = -2; offsetY <= 2; offsetY++) {
                    for (let offsetX = -2; offsetX <= 2; offsetX++) {
                        if (offsetX === 0 && offsetY === 0) continue;
                        const neighborX = x + offsetX;
                        const neighborY = y + offsetY;
                        if (neighborX < 0 || neighborX >= img.width || neighborY < 0 || neighborY >= img.height) continue;
                        const neighborIndex = (neighborY * img.width + neighborX) * 4;
                        const neighborAlpha = imgData.data[neighborIndex + 3];
                        if (neighborAlpha < 200) return true;
                    }
                }

                return false;
            };


            // Generate particles with variable density
            for (let y = 0; y < img.height; y += step) {
                for (let x = 0; x < img.width; x += step) {
                    const index = (y * img.width + x) * 4;
                    const alpha = imgData.data[index + 3];

                    if (alpha > 200) {
                        const posX = x - img.width / 2;
                        const posY = -(y - img.height / 2);
                        const posZ = 0;

                        positions.push(posX, posY, posZ);

                        const [r, g, b] = generateGrayColor();
                        colors.push(r, g, b);
                        const size = generateRandomSize();
                        sizes.push(size);

                        angles.push(generateRandomAngle());

                        windOffsets.push({ x: 0, y: 0 });

                        const distanceFromCenter = Math.sqrt(posX * posX + posY * posY);
                        const maxDistance = Math.sqrt((img.width / 2) ** 2 + (img.height / 2) ** 2);
                        const alphaValue = 0.8 + 0.1 * (1 - distanceFromCenter / maxDistance); // Value between 0.5 and 1
                        alphas.push(alphaValue);

                        // Adjust velocities to always move upwards and to the left
                        if (isEdgePixel(x, y)) {
                            const numParticles = Math.floor(Math.random() * 10) + 5;
                            for (let i = 0; i < numParticles; i++) {
                                const smallPosX = posX + Math.random() * 2 - 1;
                                const smallPosY = posY + Math.random() * 2 - 1;

                                const velocity = {
                                    x: (Math.random() * -0.005 - 0.0025) * snowflakeSpeedFactor, // Negative x to move left
                                    y: (Math.random() * 0.005 + 0.0025) * snowflakeSpeedFactor, // Positive y to move upwards
                                };
                                const acceleration = generateAcceleration();

                                const smallLifetime = (Math.random() * 2 + 1) * 2;
                                const waveParam = generateWaveParams();
                                const delay = Math.random() * 1;
                                const smallSize = generateRandomSize();
                                const fadeDelay = 0.1;
                                const merged = false;

                                smallPositions.push(smallPosX, smallPosY, posZ - 1); // Position snowflakes behind the silhouette
                                smallVelocities.push(velocity.x, velocity.y, 0);
                                smallAccelerations.push(acceleration.x, acceleration.y, 0);
                                smallLifetimes.push(smallLifetime);
                                smallOpacities.push(1.0);
                                waveParams.push(waveParam);
                                delays.push(delay);
                                fadeDelays.push(fadeDelay);
                                smallSizes.push(smallSize);
                                mergedStatus.push(merged);
                                const [rSmall, gSmall, bSmall] = generateSmallParticleColor();
                                smallColors.push(rSmall, gSmall, bSmall);
                            }
                        }
                    }
                }
            }

            // NEW CODE: Generate snowflakes from the top-left corner down 50 pixels along the left edge
            const maxY = 50; // 50 pixels down from the top-left corner
            for (let y = 0; y < maxY; y += step) {
                const x = 0; // Left edge
                const index = (y * img.width + x) * 4;
                const alpha = imgData.data[index + 3];

                if (alpha > 200) {
                    const posX = x - img.width / 2;
                    const posY = -(y - img.height / 2);
                    const posZ = 0;

                    const numParticles = Math.floor(Math.random() * 10) + 5;
                    for (let i = 0; i < numParticles; i++) {
                        const smallPosX = posX + Math.random() * 2 - 1;
                        const smallPosY = posY + Math.random() * 2 - 1;

                        const velocity = {
                            x: (Math.random() * -0.005 - 0.0025) * snowflakeSpeedFactor, // Negative x to move left
                            y: (Math.random() * 0.005 + 0.0025) * snowflakeSpeedFactor,
                        };
                        const acceleration = generateAcceleration();

                        const smallLifetime = (Math.random() * 2 + 1) * 2;
                        const waveParam = generateWaveParams();
                        const delay = Math.random() * 1;
                        const smallSize = generateRandomSize();
                        const fadeDelay = 0.1;
                        const merged = false;

                        smallPositions.push(smallPosX, smallPosY, posZ - 1); // Position snowflakes behind the silhouette
                        smallVelocities.push(velocity.x, velocity.y, 0);
                        smallAccelerations.push(acceleration.x, acceleration.y, 0);
                        smallLifetimes.push(smallLifetime);
                        smallOpacities.push(1.0);
                        waveParams.push(waveParam);
                        delays.push(delay);
                        fadeDelays.push(fadeDelay);
                        smallSizes.push(smallSize);
                        mergedStatus.push(merged);
                        const [rSmall, gSmall, bSmall] = generateSmallParticleColor();
                        smallColors.push(rSmall, gSmall, bSmall);
                    }
                }
            }

            particlesGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
            particlesGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
            particlesGeometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));
            particlesGeometry.setAttribute('alpha', new THREE.Float32BufferAttribute(alphas, 1)); // Set alpha attribute

            smallParticlesGeometry.setAttribute('position', new THREE.Float32BufferAttribute(smallPositions, 3));
            smallParticlesGeometry.setAttribute('color', new THREE.Float32BufferAttribute(smallColors, 3));
            smallParticlesGeometry.setAttribute('size', new THREE.Float32BufferAttribute(smallSizes, 1));
            smallParticlesGeometry.setAttribute('opacity', new THREE.Float32BufferAttribute(smallOpacities, 1)); // Opacity for small particles

            smallParticlesRef.current = {
                positions: smallPositions,
                velocities: smallVelocities,
                accelerations: smallAccelerations,
                lifetimes: smallLifetimes,
                opacities: smallOpacities,
                waveParams: waveParams,
                delays: delays,
                sizes: smallSizes,
                fadeDelays: fadeDelays,
                mergedStatus: mergedStatus,
                speedAdjusted: new Array(smallPositions.length / 3).fill(false), // Add flag for speed control
            };

            // ShaderMaterial for large particles (with depth writing and opaque blending)
            const particlesMaterial = new THREE.ShaderMaterial({
                uniforms: {},
                vertexShader: `
                    attribute float size;
                    attribute vec3 color;
                    attribute float alpha;
                    varying vec3 vColor;
                    varying float vAlpha;
                    void main() {
                        vColor = color;
                        vAlpha = alpha;
                        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                        gl_PointSize = size * (300.0 / -mvPosition.z);
                        gl_Position = projectionMatrix * mvPosition;
                    }
                `,
                fragmentShader: `
                    varying vec3 vColor;
                    varying float vAlpha;
                    void main() {
                        gl_FragColor = vec4(vColor, vAlpha);
                    }
                `,
                transparent: true, // Keep transparent to handle alpha changes
                depthWrite: true,   // Enable depth writing
                depthTest: true,    // Enable depth testing
            });

            // ShaderMaterial for small particles with per-vertex transparency
            const smallParticlesMaterial = new THREE.ShaderMaterial({
                uniforms: {},
                vertexShader: `
                    attribute float size;
                    attribute vec3 color;
                    attribute float opacity;
                    varying vec3 vColor;
                    varying float vOpacity;
                    void main() {
                        vColor = color;
                        vOpacity = opacity;
                        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                        gl_PointSize = size * (300.0 / -mvPosition.z);
                        gl_Position = projectionMatrix * mvPosition;
                    }
                `,
                fragmentShader: `
                    varying vec3 vColor;
                    varying float vOpacity;
                    void main() {
                        gl_FragColor = vec4(vColor, vOpacity);
                    }
                `,
                transparent: true,
                blending: THREE.AdditiveBlending,
                depthWrite: false, // Do not write to depth buffer
                depthTest: true,   // Enable depth testing to respect the silhouette
            });

            const particles = new THREE.Points(particlesGeometry, particlesMaterial);
            const smallParticles = new THREE.Points(smallParticlesGeometry, smallParticlesMaterial);

            // Set render order for correct rendering
            particles.renderOrder = 1;
            smallParticles.renderOrder = 2;

            scene.add(particles);
            scene.add(smallParticles);

            const renderPass = new RenderPass(scene, camera);
            composer.addPass(renderPass);

            const hBlur = new ShaderPass(HorizontalBlurShader);
            hBlur.uniforms.h.value = 0.1 / canvasWidth;
            composer.addPass(hBlur);

            const vBlur = new ShaderPass(VerticalBlurShader);
            vBlur.uniforms.v.value = 0.1 / canvasHeight;
            composer.addPass(vBlur);

            particlesGeometryRef.current = particlesGeometry;
            smallParticlesGeometryRef.current = smallParticlesGeometry;

            camera.left = -imgSize.current.width / 2;
            camera.right = imgSize.current.width / 2;
            camera.top = imgSize.current.height / 2;
            camera.bottom = -imgSize.current.height / 2;
            camera.updateProjectionMatrix();
            camera.position.z = 500;

            // Initialize particle offsets and delays due to mouse interaction
            const numParticles = positions.length / 3;
            particleMouseOffsetsRef.current = new Float32Array(positions.length).fill(0);
            particleDelaysRef.current = new Array(numParticles).fill(0);

            // Cursor radius in pixels
            const cursorRadiusInPixels = 10;

            // Function to convert pixels to world units
            const convertPixelsToWorldUnits = (pixels, camera, canvasHeight) => {
                const halfHeight = canvasHeight / 2;
                const halfWorldHeight = camera.top - camera.bottom;
                return (pixels / halfHeight) * halfWorldHeight;
            };

            const maxDistance = convertPixelsToWorldUnits(cursorRadiusInPixels, camera, canvasHeight);

            // Function to calculate distance from point to line segment
            const getDistanceToSegment = (px, py, x1, y1, x2, y2) => {
                const l2 = (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1);
                if (l2 === 0) return Math.hypot(px - x1, py - y1);
                let t = ((px - x1) * (x2 - x1) + (py - y1) * (y2 - y1)) / l2;
                t = Math.max(0, Math.min(1, t));
                const projX = x1 + t * (x2 - x1);
                const projY = y1 + t * (y2 - y1);
                return Math.hypot(px - projX, py - projY);
            };

            const animateParticles = () => {
                const smallParticlesData = smallParticlesRef.current;
                const smallPositions = smallParticlesGeometryRef.current.attributes.position.array;
                const smallVelocities = smallParticlesData.velocities;
                const smallAccelerations = smallParticlesData.accelerations;
                const smallLifetimes = smallParticlesData.lifetimes;
                const smallOpacities = smallParticlesData.opacities;
                const waveParams = smallParticlesData.waveParams;
                const delays = smallParticlesData.delays;
                const smallSizes = smallParticlesData.sizes;
                const fadeDelays = smallParticlesData.fadeDelays;
                const mergedStatus = smallParticlesData.mergedStatus;
                const speedAdjusted = smallParticlesData.speedAdjusted; // Get speed flags

                // Animate small particles (snowflakes)
                for (let i = 0; i < smallPositions.length; i += 3) {
                    const index = i / 3;

                    if (delays[index] > 0) {
                        delays[index] -= 0.008; // Halved the decrement to slow down delays (original was 0.016)
                        continue;
                    }

                    if (smallLifetimes[index] > 0) {
                        // Update velocities with acceleration
                        smallVelocities[i] += smallAccelerations[i];
                        smallVelocities[i + 1] += smallAccelerations[i + 1];

                        // Apply velocities to positions
                        smallPositions[i] += smallVelocities[i];
                        smallPositions[i + 1] += smallVelocities[i + 1];

                        // Wave motion
                        const { amplitude, frequency } = waveParams[index];
                        smallPositions[i] += Math.sin(smallPositions[i + 1] * frequency) * amplitude * 0.5; // Halved amplitude

                        // Decrease lifetime
                        smallLifetimes[index] -= 0.0125; // Halved the decrement to slow down lifetime decrease

                        // Handle merging remains the same...

                        if (fadeDelays[index] > 0) {
                            fadeDelays[index] -= 0.005; // Halved the decrement
                        } else {
                            smallOpacities[index] -= 0.0125; // Halved the decrement
                            if (smallOpacities[index] < 0) smallOpacities[index] = 0;
                        }
                    } else {
                        // Reset particle
                        const targetIndex = Math.floor(Math.random() * positions.length / 3) * 3;
                        const posX = positions[targetIndex];
                        const posY = positions[targetIndex + 1];

                        const velocity = {
                            x: (Math.random() * -0.005 - 0.0025) * snowflakeSpeedFactor, // Negative x to move left
                            y: (Math.random() * 0.005 + 0.0025) * snowflakeSpeedFactor,
                        };
                        const acceleration = generateAcceleration();

                        const waveParam = generateWaveParams();
                        const delay = Math.random() * 1;
                        const smallSize = generateRandomSize();
                        const fadeDelay = 0.1;

                        smallPositions[i] = posX;
                        smallPositions[i + 1] = posY;
                        smallVelocities[i] = velocity.x;
                        smallVelocities[i + 1] = velocity.y;
                        smallAccelerations[i] = acceleration.x;
                        smallAccelerations[i + 1] = acceleration.y;
                        smallLifetimes[index] = (Math.random() * 2 + 1) * 2;
                        smallOpacities[index] = 1.0;
                        waveParams[index] = waveParam;
                        delays[index] = delay;
                        smallSizes[index] = smallSize;
                        fadeDelays[index] = fadeDelay;
                        mergedStatus[index] = false;
                        speedAdjusted[index] = false; // Reset speed flag
                    }
                }

                smallParticlesGeometryRef.current.attributes.position.needsUpdate = true;
                smallParticlesGeometryRef.current.attributes.size.needsUpdate = true;
                smallParticlesGeometryRef.current.attributes.opacity.needsUpdate = true; // Update opacity

                const largePositions = particlesGeometryRef.current.attributes.position.array;
                const alphasArray = particlesGeometryRef.current.attributes.alpha.array;
                const particleMouseOffsets = particleMouseOffsetsRef.current;
                const particleDelays = particleDelaysRef.current;

                const currentTime = Date.now();

                // Adjusted damping for slower return
                const damping = 0.975; // Increased damping for slower movement (original was 0.95)
                const maxForce = 1000; // Reduced max force to slow down mouse interaction effects

                const time = currentTime * 0.00005; // Halved the time scaling factor

                for (let i = 0; i < largePositions.length; i += 3) {
                    const index = i / 3;
                    const angle = angles[index] + rotationSpeed;
                    const radius = 2.5; // Halved the radius
                    const originalX = positions[i];
                    const originalY = positions[i + 1];

                    const windOffset = windOffsets[index];

                    // Update positions based on rotation and wind
                    largePositions[i] = originalX + radius * Math.cos(angle) + windOffset.x;
                    largePositions[i + 1] = originalY + radius * Math.sin(angle) + windOffset.y;

                    angles[index] = angle;

                    // Decrease particle delay
                    if (particleDelays[index] > 0) {
                        particleDelays[index] -= 0.008; // Halved the decrement
                        continue;
                    }

                    // Initialize alpha channel
                    let alpha = 1.0;

                    // Mouse interaction
                    if (mousePosition.current.isActive) {
                        const distance = getDistanceToSegment(
                            largePositions[i],
                            largePositions[i + 1],
                            mousePosition.current.previousX,
                            mousePosition.current.previousY,
                            mousePosition.current.x,
                            mousePosition.current.y
                        );

                        // Calculate force as a continuous function
                        const strength = Math.max(0, 1 - (distance / maxDistance));

                        // Update alpha based on force
                        alpha *= (1.0 - strength);

                        if (strength > 0) {
                            // Use noise function for random force direction
                            const nx = (largePositions[i] + maxDistance) / (maxDistance * 2);
                            const ny = (largePositions[i + 1] + maxDistance) / (maxDistance * 2);

                            const angleNoise = noise3D.current(nx * 5, ny * 5, time) * Math.PI * 2;
                            const magnitudeNoise = noise3D.current((nx + 100) * 5, (ny + 100) * 5, time);

                            const forceMagnitude = magnitudeNoise * maxForce * strength;
                            const forceX = Math.cos(angleNoise) * forceMagnitude;
                            const forceY = Math.sin(angleNoise) * forceMagnitude;

                            particleMouseOffsets[i] += forceX;
                            particleMouseOffsets[i + 1] += forceY;
                        }
                    }

                    // Apply damping to offsets
                    particleMouseOffsets[i] *= damping;
                    particleMouseOffsets[i + 1] *= damping;

                    // Apply offsets to positions
                    largePositions[i] += particleMouseOffsets[i];
                    largePositions[i + 1] += particleMouseOffsets[i + 1];

                    // Update alpha value
                    alphasArray[index] = alpha;
                }

                particlesGeometryRef.current.attributes.position.needsUpdate = true;
                particlesGeometryRef.current.attributes.alpha.needsUpdate = true; // Update alpha attribute

                composer.render();
                requestAnimationFrame(animateParticles);
            };

            animateParticles();
        });

        const handleResize = () => {
            renderer.setSize(canvasWidth, canvasHeight);
            camera.left = -imgSize.current.width / 2;
            camera.right = imgSize.current.width / 2;
            camera.top = imgSize.current.height / 2;
            camera.bottom = -imgSize.current.height / 2;
            camera.updateProjectionMatrix();
        };

        window.addEventListener('resize', handleResize);

        // Mouse event handlers
        const onMouseMove = (event) => {
            const rect = canvasRef.current.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            const mouseX = (x / rect.width) * 2 - 1;
            const mouseY = - (y / rect.height) * 2 + 1;

            const vec = new THREE.Vector3(mouseX, mouseY, 0.5);
            vec.unproject(camera);

            // Store previous mouse position
            if (mousePosition.current.isActive) {
                mousePosition.current.previousX = mousePosition.current.x;
                mousePosition.current.previousY = mousePosition.current.y;
            } else {
                mousePosition.current.previousX = vec.x;
                mousePosition.current.previousY = vec.y;
            }

            mousePosition.current.x = vec.x;
            mousePosition.current.y = vec.y;
            mousePosition.current.isActive = true;
            mousePosition.current.lastMoveTime = Date.now();
        };

        const onMouseLeave = () => {
            mousePosition.current.isActive = false;
        };

        canvasRef.current.addEventListener('mousemove', onMouseMove);
        canvasRef.current.addEventListener('mouseleave', onMouseLeave);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (canvasRef.current) {
                canvasRef.current.removeEventListener('mousemove', onMouseMove);
                canvasRef.current.removeEventListener('mouseleave', onMouseLeave);
            }
            renderer.dispose();
        };
    }, []);

    return <canvas className="canvas" ref={canvasRef} style={{ display: 'block' }} />;
};

export default LogoParticles;
